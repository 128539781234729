@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
    z-index: 1;
}

.top-bar {
    box-shadow: 0px 5px 5px #101010;
    z-index: 2;
}

.card-container {
    transition: 0.2s;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border: 1px #333 solid;
    box-shadow: -2px 2px 10px #101010;
}

.card-container:hover {
  box-shadow: 0px 10px 10px #101010;
  transform: perspective(200px) rotateX(1deg);
}

.card-container::after {
  transition: 0.2s;
  position: absolute;
  content: "";
  display: inline-block;
  font-weight: bold;
  opacity: 0.08;
  color: #fff;
  background-color: #fff;
  top: -200px;
  right: -150px;
  width: 300px;
  height: 300px;
  transform: rotate(-45deg);
  pointer-events: none;
  border: 10px rgba(0, 0, 0, 0.3) solid;
}

.card-container:hover::after {
  height: 450px;
  opacity: 0.1;
}

/* Initial style without fading */
.fade {
/*  transition: opacity 5s ease-in-out;*/
  opacity: 1;
  animation: floatInCard .5s linear;
}

.fadeAndGrow {
    opacity: 1;
    animation: floatInCard .5s ease forwards;
}
@keyframes fadeAndGrow {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
/* Add this class to fade out */
.fade-out {
    opacity: 0;
    animation: fadeOut .5s linear;
}

@keyframes fadeOut {
    0%{
        opacity: 1;
    }
    50% {
        opacity: .2;
    }
    100%{
        opacity: 0;
    }
}

.fade-out-up {
    opacity: 0;
    animation: floatUpCard .5s ease-in;
}
@keyframes floatUpCard {
    0%{
        transform: translateY(0);
        opacity: .5;
        border-radius: 0;
    }
    25% {
        transform: scale(.90);
    }
    50% {
        opacity: .2;
    }

    100%{
        transform: translateY(-50px) translateX(0) scale(.90);
        opacity: 0;
    }

}

@keyframes floatInCard {
    0%{
        transform: translateY(50px);
        opacity: 0;
    }
    50% {
        opacity: .8;
    }
    100%{
        transform: translateY(0px) translateX(0);
        opacity: 1;
    }
}

.search-card {
  transition: height 0.5s ease-in-out;
}

.search-card.expanded {
  height: 550px;
}
